<template>
  <div>
    <ManageChangePassword />
  </div>
</template>
<script>
import ManageChangePassword from "@/components/ChangePassword/ManageChangePassword";
export default {
  components: {
    ManageChangePassword,
  },
  created() {},
};
</script>
