<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-card outlined class="pa-6">
      <v-row>
        <v-col cols="12" md="12" sm="12" style="margin-bottom: 4px">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 700;
              font-size: 31px;
              line-height: 51px;
            "
          >
            เปลี่ยนรหัสผ่าน
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <span>รหัสผ่านใหม่</span>
          <v-text-field
            v-model="newPassword"
            dense
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <span>ยืนยันรหัสผ่านใหม่</span>
          <v-text-field
            v-model="confirmNewPassword"
            dense
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-btn
            @click="checkCorrectPassword()"
            color="#2AB3A3"
            dark
            style="padding: 12px 16px; gap: 8px; width: 158px; height: 48px"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import DialogBanners from "../Dialogs/DialogBanners.vue";
import DialogBannersEdit from "../Dialogs/DialogBannersEdit.vue";
import DialogGallery from "../Dialogs/DialogGallery.vue";
import DialogGalleryEdit from "../Dialogs/DialogGalleryEdit.vue";
import DialogOldStudents from "../Dialogs/DialogOldStudents.vue";
import DialogOldStudentsEdit from "../Dialogs/DialogOldStudentsEdit.vue";

export default {
  components: {
    draggable,
    Loading,
    DialogBanners,
    DialogBannersEdit,
    DialogGallery,
    DialogGalleryEdit,
    DialogOldStudents,
    DialogOldStudentsEdit,
  },
  data() {
    return {
      loading: false,
      newPassword: "",
      confirmNewPassword: "",
      user: {},
    };
  },

  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
  },

  mounted() {},

  methods: {
    async checkCorrectPassword() {
      if (this.newPassword === this.confirmNewPassword) {
        this.sendApi();
      } else {
        this.$swal.fire({
          icon: "error",
          text: "รหัสผ่านไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    async sendApi() {
      this.loading = true;

      this.$swal({
        text: "คุณต้องการเปลี่ยนรหัสผ่าน หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const data = {
            id: this.user.id,
            password: this.newPassword,
          };
          const response = await this.axios.put(
            `${process.env.VUE_APP_API}/users/${this.user.id}`,
            data,
            auth
          );
          console.log("response", response.data.data);

          this.$swal.fire({
            icon: "success",
            text: "เปลี่ยนรหัสผ่านสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      });
      this.loading = false;
    },

    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/banners/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbanner();
        }
      });
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .hidden-button {
    display: none !important; /* Use !important to override other styles */
  }
}
</style>




